import { useEffect } from "react";
import { Container } from "react-bootstrap";

function PleaseLogin() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("http://localhost:3000/login");
    }, 1500);
  }, []);
  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        fontSize: "20px",
      }}
    >
      You are not logged In
    </Container>
  );
}

export default PleaseLogin;
