import { Alert } from "react-bootstrap";

export default function MsgBox({ result, setRes }) {
  var msg = result["msg"];
  var success = result["success"];
  if (msg == null) return <></>;
  else if (success) {
    return (
      <Alert variant="info" onClose={() => setRes({ msg: null })} dismissible>
        <Alert.Heading>Yayy!!</Alert.Heading>
        <p>{msg}</p>
      </Alert>
    );
  } else {
    return (
      <Alert variant="danger" onClose={() => setRes({ msg: null })} dismissible>
        <Alert.Heading>Oh snap!</Alert.Heading>
        <p>{msg}</p>
      </Alert>
    );
  }
}
