import { getDatabase, get, query, ref, orderByChild } from "firebase/database";
import { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useSearchParams, Link } from "react-router-dom";
import DesignDetail from "./design";
import Rating from "../components/rating";
import { design } from "../models/designModel";
import "../style/design.css";

function Designs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [designList, setDesignList] = useState([]);
  const [loading, setLoading] = useState(false);
  var db = getDatabase();

  useEffect(() => {
    if (id == null || id == "") {
      setLoading(true);
      get(query(ref(db, "designs/"), orderByChild("category")))
        .then((snapshot) => {
          if (snapshot.exists()) {
            let list = [];
            snapshot.forEach((child) => {
              let d = new design(child.val());
              list.push(d);
            });
            setDesignList(list);
          } else {
            console.log("No data available");
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  function generateDesignWall(designs) {
    let currentCategory = "abc";
    let wall = [];
    let i = 0;
    while (i < designs.length) {
      if (currentCategory !== designs[i].category) {
        wall.push(
          <Row style={{ marginTop: "20px" }}>
            <Col id={designs[i].category}>
              <h2>{designs[i].category.toUpperCase()}</h2>
              <hr className="divider"></hr>
            </Col>
          </Row>
        );
        currentCategory = designs[i].category;
      }
      let cols = [];
      while (i < designs.length) {
        if (currentCategory !== designs[i].category) break;
        cols.push(
          <Col xs={6} md={3} style={{ marginTop: "10px" }}>
            <Link
              to={"/designs?id=" + designs[i].id}
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card>
                <Card.Body>
                  <Container style={{ padding: "0px" }}>
                    <Row>
                      <Col className="centr">
                        <img src={designs[i].images[0]} className="rsImg" />
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "20px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      className="d-md-block"
                    >
                      <Col style={{ maxLines: 2 }}>{designs[i].name}</Col>
                    </Row>
                    <Row>
                      <Col
                        style={{
                          textAlign: "end",
                          fontSize: "0.8rem",
                          marginTop: "10px",
                        }}
                      >
                        <Rating val={designs[i].rating} flag={false} />
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        );
        i++;
      }
      wall.push(<Row>{cols}</Row>);
    }
    return wall;
  }

  if (loading) {
    return (
      <Container className="loader">
        <img src="../../assets/loader.gif" height="100px" />
      </Container>
    );
  } else if (id != null && id != "") {
    return <DesignDetail id={id}></DesignDetail>;
  } else {
    return (
      <Container
        style={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {generateDesignWall(designList)}
      </Container>
    );
  }
}

export default Designs;
