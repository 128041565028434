import { Container, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { getDatabase, ref, get, remove } from "firebase/database";
import { design } from "../../models/designModel";
import ImageCarousel from "../../components/image_carousel";
import Loader from "../../components/loading";
import MsgBox from "../../components/msgbox";

function DeleteDesign() {
  var [designItem, setDesignItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const db = getDatabase();
  const [designId, setDesignId] = useState("");
  var [result, setRes] = useState({ success: false, msg: null });

  const handleShow = (event) => {
    event.preventDefault();
    setLoading(true);
    setDesignItem(null);
    setRes({ msg: null });
    var id = document.getElementById("designId").value;
    setDesignId(id);
    get(ref(db, `designs/${id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          var d = new design(snapshot.val());
          setDesignItem(d);
          console.log(d);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (event) => {
    event.preventDefault();
    setLoading(true);
    setRes({ msg: null });
    console.log("id " + designId);
    remove(ref(db, `designs/${designId}`))
      .then(() => {
        console.log("Deleted");
        setRes({ success: true, msg: "Design deleted successfully" });
      })
      .catch((e) => {
        console.log(e);
        setRes({ success: false, msg: `${e}` });
      })
      .finally(() => {
        setLoading(false);
        setDesignId("");
      });
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      <Row>
        <Col md={4} />
        <Col md={4}>
          <form className="admin-form">
            <label>
              Enter the design id
              <input
                id="designId"
                className="form-control"
                placeholder="Design Id"
              />
            </label>
            <button className="button" onClick={handleShow}>
              Search
            </button>
          </form>
        </Col>
      </Row>
      <Row>
        {designItem && (
          <Container>
            <Row className="d-md-none">
              <h2>{designItem.name}</h2>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col xs={12} md={6}>
                <ImageCarousel
                  images={designItem.images}
                  height={"450px"}
                  zoom={true}
                />
              </Col>
              <Col xs={12} md={6}>
                <div className="d-none d-md-block">
                  <h2>{designItem.name}</h2>
                </div>
                <p style={{ marginTop: "10px" }}>{designItem.description}</p>
                <Button
                  variant="outline-dark"
                  onClick={handleDelete}
                  style={{ marginBottom: "10px" }}
                >
                  Delete Item
                </Button>
                <MsgBox result={result} setRes={setRes} />
              </Col>
            </Row>
            <Row></Row>
          </Container>
        )}
      </Row>
      <Loader loading={loading} />
    </Container>
  );
}
export default DeleteDesign;
