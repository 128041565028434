import { Container, Row, Col, Button, Carousel } from "react-bootstrap";
import ImageCarousel from "../components/image_carousel";
import Rating from "../components/rating";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import Review from "../components/review";
import { getDatabase, ref, get, equalTo, query } from "firebase/database";
import { review } from "../models/reviewModel";
import { design } from "../models/designModel";
import "../style/design.css";
import { Link } from "react-router-dom";

function DesignDetail({ id }) {
  const [showGallery, setShowGallery] = useState(false);
  const [shownReview, setShownReview] = useState(null);
  const [shownPreview, setShownPreview] = useState(null);
  const handleShowReview = (review) => {
    setShownReview(review);
    setShowGallery(false);
  };
  const db = getDatabase();
  const [loading, setLoading] = useState(true);
  const [rloading, setRloading] = useState(true);
  var [designItem, setDesignItem] = useState(null);
  var [reviewData, setReviewData] = useState([]);
  var [photos, setPhotos] = useState([]);

  useEffect(() => {
    fetchDesign();
  }, []);

  function fetchDesign() {
    setLoading(true);
    get(ref(db, `designs/${id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          var d = new design(snapshot.val());
          setDesignItem(d);
          console.log(d);
          fetchReview();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function fetchReview() {
    setRloading(true);
    get(query(ref(db, `reviews`), equalTo(id, "designId")))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let list = [];
          let photoList = [];
          snapshot.forEach((child) => {
            var r = new review(child.val());
            list.push(r);
            r.images.forEach((i) => photoList.push(i));
          });
          setReviewData(list);
          setPhotos(photoList);
          console.log(list);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setRloading(false);
      });
  }

  function getPhotoWall(reviews) {
    let wall = [];
    reviews.forEach((r) => {
      r.images.forEach((i) => {
        wall.push(
          <img
            src={i}
            onClick={() => handleShowReview(r)}
            style={{ maxWidth: "15%", margin: "5px" }}
          />
        );
      });
    });
    if (wall.length > 4) {
      wall = wall.slice(0, 4);
      wall.push(
        <span className="containers" onClick={() => setShowGallery(true)}>
          <img
            src="../assets/blouse-blur.jpg"
            style={{ maxWidth: "15%", margin: "5px" }}
          />
          <span className="centered">+ More</span>
        </span>
      );
    }
    return wall;
  }

  function getReviewWall(reviews) {
    let wall = [];
    reviews.map((r) => {
      let imgs = [];
      r.images.map((i) => {
        imgs.push(
          <img
            style={{ height: "100px", marginTop: "10px" }}
            src={i}
            onClick={() => handleShowReview(r)}
          ></img>
        );
      });
      wall.push(
        <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
          <Col md={1} xs={3}>
            <Rating val={r.rating} flag={false} />
          </Col>
          <Col md={11} xs={9}>
            <div>{r.description}</div>
            <div>{imgs}</div>
            <div style={{ marginTop: "5px", color: "grey" }}>{r.name}</div>
          </Col>
        </Row>,
        <Row>
          <hr className="divider"></hr>
        </Row>
      );
    });
    return wall;
  }

  function placeOrder(event) {
    event.preventDefault();
    const placeOrderLink =
      "https://wa.me/+919473751945?text=" +
      "Hi%20there%2C%20%0AI%20would%20like%20to%20get%20my%20clothes%20stiched%20according%20to%20this%20design%20(vmake.in%2Fdesigns%3Fid%3D" +
      id +
      ").%20Can%20we%20discuss%20about%20it%20and%20confirm%20my%20order%3F";

    window.open(placeOrderLink, "_blank");
  }

  if (loading) {
    return (
      <Container className="loader">
        <img src="../../assets/loader.gif" height="100px" />
      </Container>
    );
  } else {
    return (
      <Container>
        <Row className="d-md-none">
          <h2 style={{ marginTop: "20px" }}>{designItem.name}</h2>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={12} md={6}>
            <ImageCarousel
              images={designItem.images}
              zoom={true}
              height={"450px"}
              handler={setShownPreview}
            />
          </Col>
          <Col xs={12} md={6}>
            <div className="d-none d-md-block">
              <h2 style={{ marginBottom: "20px" }}>{designItem.name}</h2>
              <Rating val={designItem.rating} count={16} flag={true} />
            </div>
            <p style={{ marginTop: "10px" }}>{designItem.description}</p>
            Get your own {designItem.name} stitched for :
            <h4 style={{ color: "green", marginBottom: "15px" }}>
              ₹{designItem.price_start} - ₹{designItem.price_end}
            </h4>
            Liked the design? Place your Order or Contact Us for any queries
            <Row style={{ marginTop: "5px" }}>
              <Col className="d-grid gap-2">
                <Button variant="primary" onClick={placeOrder}>
                  Place Order
                </Button>
              </Col>
              <Col className="d-grid gap-2">
                <Link to="../contact-us" style={{ textDecoration: "none" }}>
                  <Col className="d-grid gap-2">
                    <Button variant="secondary" onClick={null}>
                      Contact Us
                    </Button>
                  </Col>
                </Link>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
                <h4>Customer Photos</h4>
                {rloading ? (
                  <Container className="loader">
                    <img src="../../assets/loader.gif" height="50px" />
                  </Container>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    {reviewData == null || reviewData.length == 0 ? (
                      <div>No data available</div>
                    ) : (
                      getPhotoWall(reviewData)
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: "10px" }}>
            <h4 style={{ marginBottom: "15px" }}>Customer Reviews</h4>
            {reviewData == null || reviewData.length == 0 ? (
              <div style={{ marginBottom: "20px" }}>No reviews available</div>
            ) : (
              <Container fluid>{getReviewWall(reviewData)}</Container>
            )}
          </Col>
        </Row>

        <Modal show={showGallery} onHide={() => setShowGallery(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Customer Photos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {photos.map((i) => {
                <Col md={4} xs={6}>
                  <img
                    style={{ maxWidth: "100%", margin: "5px" }}
                    onClick={() => setShownReview(null)}
                    src={i}
                  ></img>
                </Col>;
              })}
            </Row>
          </Modal.Body>
        </Modal>

        <Modal show={shownReview !== null} onHide={() => setShownReview(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Customer Review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col style={{ textAlign: "center" }}>
                  {shownReview?.images.length > 0 ? (
                    <Carousel
                      prevIcon={<img src="../../assets/left_arrow.png" />}
                      nextIcon={<img src="../../assets/right_arrow.png" />}
                    >
                      {shownReview?.images.map((img) => (
                        <Carousel.Item key={img}>
                          <img src={img} style={{ maxWidth: "50%" }} />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <span></span>
                  )}
                </Col>
              </Row>
              <Row>
                <Review
                  value={shownReview?.rating}
                  review={shownReview?.description}
                  name={shownReview?.name}
                />
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <Modal show={shownPreview != null} onHide={() => setShownPreview(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <img style={{ maxWidth: "100%" }} src={shownPreview}></img>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

export default DesignDetail;
