import { Container, Col, Row, Modal, Carousel, Button } from "react-bootstrap";
import { useState, useRef } from "react";
import { getDatabase, ref, child, get, update } from "firebase/database";
import { design } from "../../models/designModel";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import FileInput from "./file-input";
import Loader from "../../components/loading";
import MsgBox from "../../components/msgbox";

function EditDesign() {
  var [result, setRes] = useState({ success: false, msg: "null" });
  var [designItem, setDesignItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const db = getDatabase();
  const [designId, setDesignId] = useState("");
  const [domain, setDomain] = useState("");
  const [category, setCategory] = useState("");
  const [images, setImages] = useState([]);
  const canvasRef = useRef(null);
  const modalRef = useRef(null);
  const [srcImg, setSrcImg] = useState(null);
  const [crop, setCrop] = useState({ width: 50, height: 50, aspect: 2 / 3 });
  const [idx, setIdx] = useState(0);

  const handleShow = (event) => {
    event.preventDefault();
    setLoading(true);
    setDesignItem(null);
    setRes({ msg: null });
    var id = document.getElementById("designId").value;
    setDesignId(id);
    get(ref(db, `designs/${id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          var d = new design(snapshot.val());
          setDesignItem(d);
          setDomain(d.domain);
          setCategory(d.category);
          setImages(d.images);
          console.log(d);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveEdits = (event) => {
    event.preventDefault();
    setLoading(true);
    setRes({ msg: null });
    const postData = {
      id: designId,
      category: category,
      domain: domain,
      images: images,
      name: document.getElementById("name").value,
      description: document.getElementById("desc").value,
      price_start: document.getElementById("low").value,
      price_end: document.getElementById("high").value,
      rating: document.getElementById("rating").value,
      visible: true,
    };
    console.log(postData);
    update(ref(db, "designs/" + designId), postData)
      .then((snapshot) => {
        setRes({ success: true, msg: "Design updated successfully" });
      })
      .catch((e) => {
        console.log(e);
        setRes({ success: false, msg: `${e}` });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCroppedImg = () => {
    const image = modalRef.current;
    const canvas = canvasRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    return base64Image;
  };

  function addImageToCanvas() {
    var image = getCroppedImg();
    console.log(image);
    var list = [...images, image];
    setImages(list);
    setSrcImg(null);
  }

  function deleteImg(idx) {
    var list = [...images];
    list.splice(idx, 1);
    setImages(list);
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <Row>
        <Col md={4} />
        <Col md={4}>
          <form className="admin-form">
            <label>
              Enter the design id
              <input
                id="designId"
                className="form-control"
                placeholder="Design Id"
              />
            </label>
            <button className="button" onClick={handleShow}>
              Search
            </button>
          </form>
        </Col>
      </Row>
      <Row>
        {designItem && (
          <Container fluid>
            <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Col md={7}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <form className="admin-form">
                    <div className="form-group">
                      <label htmlFor="name">Name of Product</label>
                      <input
                        id="name"
                        defaultValue={designItem.name}
                        className="form-control"
                        placeholder="Enter product name "
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="description">Product Description</label>
                      <textarea
                        id="desc"
                        defaultValue={designItem.description}
                        className="form-control"
                        placeholder="Description about product"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="domain">
                        Select Domain
                        <select
                          defaultValue={domain}
                          onChange={(e) => setDomain(e.target.value)}
                          style={{ marginLeft: "20px" }}
                        >
                          <option value="women">Women</option>
                          <option value="children">Children</option>
                        </select>
                      </label>
                    </div>
                    <div className="form-group">
                      <label htmlFor="category">
                        Select Category
                        <select
                          defaultValue={category}
                          onChange={(e) => setCategory(e.target.value)}
                          style={{ marginLeft: "20px" }}
                        >
                          <option value="blouse">Blouse</option>
                          <option value="gown">Gown</option>
                          <option value="kurti">Kurti</option>
                          <option value="lehanga">Lehanga</option>
                          <option value="plazo">Plazo</option>
                          <option value="skirt">Skirt</option>
                          <option value="suit">Suit</option>
                          <option value="top">Top</option>
                        </select>
                      </label>
                    </div>
                    <div className="form-group">
                      <label htmlFor="rating">Rating</label>
                      <input
                        id="rating"
                        defaultValue={designItem.rating}
                        className="form-control"
                        placeholder="Enter product rating (eg 4.5)"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="price">Add Price range</label>
                      <Row>
                        <Col>
                          <input
                            id="low"
                            defaultValue={designItem.price_start}
                            className="form-control"
                            placeholder="Start Amount"
                          />
                        </Col>
                        <Col>
                          <input
                            id="high"
                            defaultValue={designItem.price_end}
                            className="form-control"
                            placeholder="End Amount"
                          />
                        </Col>
                      </Row>
                    </div>
                    <FileInput onImageSelected={setSrcImg} />
                    <div>
                      <canvas
                        ref={canvasRef}
                        style={{ height: "0", width: "0", display: "none" }}
                      />
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={5}>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  {images.length > 0 ? (
                    <div>
                      <Carousel
                        onSlide={(idx, dir) => {
                          setIdx(idx);
                        }}
                        prevIcon={<img src="../../assets/left_arrow.png" />}
                        nextIcon={<img src="../../assets/right_arrow.png" />}
                      >
                        {images.map((img, idx) => (
                          <Carousel.Item key={img}>
                            <img
                              style={{ marginBottom: "10px" }}
                              src={img}
                              height="364px"
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                      <Button
                        variant="outline-danger"
                        onClick={() => deleteImg(idx)}
                      >
                        🚫 Delete
                      </Button>
                    </div>
                  ) : (
                    <img src="../assets/noimg.png" />
                  )}
                </div>
                <MsgBox result={result} setRes={setRes} />
                <Modal show={srcImg != null} onHide={() => setSrcImg(null)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Crop Image</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col style={{ textAlign: "center" }}>
                          <ReactCrop
                            crop={crop}
                            onChange={setCrop}
                            aspect={2 / 3}
                          >
                            <img src={srcImg} ref={modalRef} />
                          </ReactCrop>
                          <div>
                            <button onClick={() => addImageToCanvas()}>
                              Crop
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                </Modal>
              </Col>
            </Row>
            <Row>
              <button className="button" onClick={saveEdits}>
                Save & Upload
              </button>
            </Row>
          </Container>
        )}
      </Row>
      <Loader loading={loading} />
    </Container>
  );
}
export default EditDesign;
