import { Outlet } from "react-router-dom";
import { Offcanvas, Navbar, Container } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import PleaseLogin from "../../components/notlogin";

function AdminLayout() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const uid = Cookies.get("uid");

  function logout(event) {
    event.preventDefault();
    Cookies.remove("uid");
    window.location.replace("http://localhost:3000/");
  }

  if (uid !== null && uid !== "" && uid !== undefined) {
    console.log(uid);
    return (
      <div>
        <Navbar style={{ backgroundColor: "#1429A9", paddingLeft: "15px" }}>
          <Navbar.Brand>
            <span style={{ color: "white" }}>
              <span
                style={{
                  paddingRight: "15px",
                  fontSize: "23px",
                  cursor: "pointer",
                }}
                onClick={handleShow}
              >
                ☰
              </span>
              <Link
                to="/admin"
                style={{ textDecoration: "none", color: "white" }}
              >
                Admin Panel
              </Link>
            </span>
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <img
                src="../assets/logout.png"
                style={{ height: "30px", marginRight: "10px" }}
                onClick={logout}
              />
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        <Offcanvas
          show={show}
          onHide={handleClose}
          style={{ backgroundColor: "#1429A9" }}
        >
          <Offcanvas.Header style={{ color: "white" }} closeButton>
            <Offcanvas.Title style={{ color: "white" }}>
              VMake Admin Panel
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Link
              to="/admin/designs"
              style={{ textDecoration: "none", color: "black" }}
              onClick={handleClose}
            >
              <div className="sidenavbtn">Designs</div>
            </Link>

            <Link
              to="/admin/orders"
              style={{ textDecoration: "none", color: "black" }}
              onClick={handleClose}
            >
              <div className="sidenavbtn">Orders</div>
            </Link>
            <Link
              to="/admin/reviews"
              style={{ textDecoration: "none", color: "black" }}
              onClick={handleClose}
            >
              <div className="sidenavbtn">Reviews</div>
            </Link>
            <Link
              to="/admin/we-make"
              style={{ textDecoration: "none", color: "black" }}
              onClick={handleClose}
            >
              <div className="sidenavbtn">We Make</div>
            </Link>
            <Link
              to="/admin/homepage"
              style={{ textDecoration: "none", color: "black" }}
              onClick={handleClose}
            >
              <div className="sidenavbtn">Homepage</div>
            </Link>
          </Offcanvas.Body>
        </Offcanvas>
        <Outlet />
      </div>
    );
  } else {
    return <PleaseLogin />;
  }
}

export default AdminLayout;
