import "../style/footer.css";
import { Link } from "react-router-dom";

function MyFooter() {
  return (
    <div style={{ backgroundColor: "#171717" }}>
      <section id="footer">
        <div className="container">
          <div
            className="row text-center text-xs-center text-sm-left text-md-left"
            style={{ padding: 0 }}
          >
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h5>Sections</h5>
              <ul className="list-unstyled quick-links">
                <li>
                  <Link to="./designs">
                    <i className="fa fa-angle-double-right"></i>Men
                  </Link>
                </li>
                <li>
                  <Link to="./designs">
                    <i className="fa fa-angle-double-right"></i>Women
                  </Link>
                </li>
                <li>
                  <Link to="./designs">
                    <i className="fa fa-angle-double-right"></i>Kids
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h5>Pages</h5>
              <ul className="list-unstyled quick-links">
                <li>
                  <Link to="./designs">
                    <i className="fa fa-angle-double-right"></i>Designs
                  </Link>
                </li>
                <li>
                  <Link to="./we-make">
                    <i className="fa fa-angle-double-right"></i>Testimonials
                  </Link>
                </li>
                <li>
                  <Link to="./contact-us">
                    <i className="fa fa-angle-double-right"></i>Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h5>Connect with us</h5>
              <ul className="list-unstyled quick-links">
                <li>
                  <a href="https://wa.me/+919473751945">
                    <i className="fa fa-angle-double-right"></i>Whatsapp
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100088545964499">
                    <i className="fa fa-angle-double-right"></i>Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/vmake_boutique/">
                    <i className="fa fa-angle-double-right"></i>Instagram
                  </a>
                </li>
                <li>
                  <a href="mailto:vmakeboutique@gmail.com">
                    <i className="fa fa-angle-double-right"></i>Email
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
              <ul className="list-unstyled list-inline social text-center">
                <li className="list-inline-item">
                  <a href="https://wa.me/+919473751945">
                    <i className="fa fa-whatsapp"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/profile.php?id=100088545964499">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/vmake_boutique/">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://in.pinterest.com/vmake3310/">
                    <i className="fa fa-pinterest"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="mailto:vmakeboutique@gmail.com" target="_blank">
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
              </ul>
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
              <p>Copyright © 2023. Site Hosted & Developed By VMAKE PVT. LTD</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyFooter;
