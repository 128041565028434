export class design {
  constructor(data) {
    this.id = data["id"];
    this.name = data["name"];
    this.description = data["description"];
    this.category = data["category"];
    this.domain = data["domain"];
    this.images = data["images"];
    this.price_start = data["price_start"];
    this.price_end = data["price_end"];
    this.rating = data["rating"];
    this.visible = data["visible"];
  }
}
