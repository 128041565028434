import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function MyNav() {
  let txt = {
    fontSize: "22px",
    marginLeft: "10px",
    fontFamily: "Roboto",
    color: "white",
  };
  return (
    <Navbar
      expand="lg"
      variant="dark"
      style={{
        backgroundColor: "#704342",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.4)",
      }}
    >
      <Navbar.Brand href="../">
        <img
          // src={logo}
          src="../assets/logo_border.png"
          style={{ width: "50px", marginLeft: "20px" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {/* <NavDropdown title="Designs" id="basic-nav-dropdown">
              <NavDropdown.Item href="../designs/women">Women</NavDropdown.Item>
              <NavDropdown.Item href="../designs/men">Men</NavDropdown.Item>
              <NavDropdown.Item href="../designs/children">
                Children
              </NavDropdown.Item>
              
            </NavDropdown> */}
          <Nav.Link href="../" style={txt}>
            Home
          </Nav.Link>
          <Nav.Link href="../designs" style={txt}>
            Designs
          </Nav.Link>
          <Nav.Link href="../we-make" style={txt}>
            We Make
          </Nav.Link>
          <Nav.Link href="../contact-us" style={txt}>
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MyNav;
