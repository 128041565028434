import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBCnKmgInsg9pDGfqkAmDrxhKuGmnLlxR4",
  authDomain: "vmake-6d9f4.firebaseapp.com",
  projectId: "vmake-6d9f4",
  storageBucket: "vmake-6d9f4.appspot.com",
  messagingSenderId: "82175729862",
  appId: "1:82175729862:web:22576ecc8d60f127a9f7c7",
  measurementId: "G-72F20KLSYL",
};

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
