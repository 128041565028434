import { getDatabase, push, ref, update } from "firebase/database";
import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import MsgBox from "./msgbox";

function ContactForm() {
  const db = getDatabase();
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState({ success: false, msg: null });

  function saveForm(event) {
    event.preventDefault();
    setLoading(true);
    let key = push(ref(db, "questions")).key;
    let data = {
      id: key,
      date: new Date(),
      name: document.getElementById("name").value,
      mobile: document.getElementById("mobile").value,
      message: document.getElementById("message").value,
    };
    update(ref(db, `questions/${key}`), data)
      .then((snapshot) => {
        setRes({ success: true, msg: "Message sent successfully" });
      })
      .catch((e) => {
        console.log(e);
        setRes({ success: false, msg: `${e}` });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleSetRes(result) {
    document.getElementById("name").value = "";
    document.getElementById("mobile").value = "";
    document.getElementById("message").value = "";
    setRes(result);
  }

  return (
    <Card>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control placeholder="Name" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="mobile">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control placeholder="Mobile Number" />
            <Form.Text className="text-muted">
              We'll not spam you with calls
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="message">
            <Form.Label>Message </Form.Label>
            <Form.Control as="textarea" placeholder="Type Your query" />
          </Form.Group>
          <Button variant="primary" type="submit" onClick={saveForm}>
            Submit
          </Button>
        </Form>
        <div style={{ marginTop: "10px" }}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <img src="../../assets/loader.gif" height="50px" />
            </div>
          ) : (
            <span></span>
          )}
          <MsgBox result={res} setRes={handleSetRes} />
        </div>
      </Card.Body>
    </Card>
  );
}

export default ContactForm;
