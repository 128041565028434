import { Modal, ModalDialog } from "react-bootstrap";

export default function Loader({ loading }) {
  return (
    <Modal show={loading} size="sm" centered>
      <Modal.Body style={{ textAlign: "center" }}>
        <img src="../../assets/loader.gif" height="100px" />
      </Modal.Body>
    </Modal>
  );
}
