import "./App.css";
import Layout from "./pages/layout";
import Home from "./pages/home";
import ContactUs from "./pages/contactUs";
import WeMake from "./pages/we_make";
import Designs from "./pages/designs";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./pages/notfound";
import DesignsAdmin from "./pages/admin/designs";
import Dashboard from "./pages/admin/dashboard";
import AdminLayout from "./pages/admin/layout";
import Reviews from "./pages/admin/reviews";
import HomePage from "./pages/admin/homepage";
import Orders from "./pages/admin/orders";
import WeMakeAdmin from "./pages/admin/we-make";
import Login from "./pages/login";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="designs" element={<DesignsAdmin />} />
            <Route path="homepage" element={<HomePage />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="orders" element={<Orders />} />
            <Route path="we-make" element={<WeMakeAdmin />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="designs" element={<Designs />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="we-make" element={<WeMake />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/login" element={<Login />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
