import { getDatabase, get, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import ImageCarousel from "../components/image_carousel";
import { wemake } from "../models/wemakeModel";
import "../style/design.css";

function WeMake() {
  const [loading, setLoading] = useState(true);
  const [wemakeData, setWemakeData] = useState([]);
  const db = getDatabase();
  var images = ["assets/gown.jpg", "assets/blouse.jpg", "assets/kurti.jpg"];

  useEffect(() => {
    fetchReviews();
  }, []);

  function fetchReviews() {
    get(ref(db, "wemake"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          var list = [];
          snapshot.forEach((child) => {
            var r = new wemake(child.val());
            list.push(r);
          });
          setWemakeData(list);
        } else {
          console.log("No data available");
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getWeMakeWall(wemake) {
    let wall = [];
    wemake.map((i) => {
      wall.push(
        <Col md={6}>
          <Card>
            <Card.Body>
              <Container fluid>
                <div
                  style={{
                    color: "grey",
                    textAlign: "end",
                    fontSize: "12px",
                    marginBottom: "10px",
                  }}
                >
                  ( id: {i.id} )
                </div>
                <Row>
                  <Col
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={i.prev_img} style={{ maxHeight: "250px" }} />
                  </Col>
                  <Col
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ImageCarousel height={"250px"} images={i.images} />
                  </Col>
                </Row>
              </Container>
              <br></br>
              <Card.Text>
                <h5>{i.name}</h5>
                <span>{i.description}</span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      );
    });
    return wall;
  }

  if (loading) {
    return (
      <Container className="loader">
        <img src="../../assets/loader.gif" height="100px" />
      </Container>
    );
  } else {
    return (
      <Container style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Row>{getWeMakeWall(wemakeData)}</Row>
      </Container>
    );
  }
}
export default WeMake;
