import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "../components/carousel";
import ContactForm from "../components/contact_form";
import ImageCarousel from "../components/image_carousel";
import "../style/home.css";

function Home() {
  var review1 = ["assets/review/3.jpg", "assets/review/4.jpg"];
  var review2 = ["assets/review/1.jpg", "assets/review/2.jpg"];
  var review3 = [
    "assets/review/5.jpg",
    "assets/review/6.jpg",
    "assets/review/7.jpg",
  ];

  return (
    <Container fluid style={{ background: "#00000" }}>
      <Row style={{ padding: "20px" }}>
        <Slider />
      </Row>
      <Container style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Row>
          <Col
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h1>Welcome to VMake</h1>
              <p>
                We provide custom-stitched and readymade clothes, as well as a
                unique upcycling service that transforms your old clothes into
                new designer garments. Our team of designers and tailors work
                closely with you to create one-of-a-kind pieces that reflect
                your personal style. Choose VMake for innovative solutions that
                cater to all your fashion needs.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} style={{ textAlign: "center" }}>
            <img src="assets/welcome.png" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <Card style={{ backgroundColor: "#704342" }}>
            <Card.Body>
              <Card.Title style={{ fontSize: "2rem", color: "white" }}>
                Check Our Categories
              </Card.Title>
              <Card.Text style={{ color: "white" }}>
                Discover your new favorite outfit by exploring our wide range of
                categories at VMake.
              </Card.Text>
              <Container fluid>
                <Row>
                  <Col
                    xs={12}
                    md={2}
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      color: "white",
                    }}
                  >
                    <div>
                      <img src="assets/women.gif" height={220} />
                      <br />
                      Women
                    </div>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/salwar.jpg" />
                      <Card.Body>
                        <Card.Text>Salwar</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/gown.jpg" />
                      <Card.Body>
                        <Card.Text>Gown</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/kurti.jpg" />
                      <Card.Body>
                        <Card.Text>Kurti</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/blouse.jpg" />
                      <Card.Body>
                        <Card.Text>Blouse</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/top.png" />
                      <Card.Body>
                        <Card.Text>Top</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="categoryCard">
                  <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <Link to="./designs" style={{ color: "white" }}>
                      View More
                    </Link>
                  </div>
                </Row>
                <br></br>
                <Row>
                  <Col
                    xs={12}
                    md={2}
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      color: "white",
                    }}
                  >
                    <div>
                      <img src="assets/children.gif" height={220} />
                      <br />
                      Children
                    </div>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/salwar_suit.jpg" />
                      <Card.Body>
                        <Card.Text>Salwar</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/frock.jpg" />
                      <Card.Body>
                        <Card.Text>Frock</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/lehanga.jpg" />
                      <Card.Body>
                        <Card.Text>Lehanga</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/anarkali_suit.jpg" />
                      <Card.Body>
                        <Card.Text>Anarkali Suit</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6} md={2} className="categoryCard">
                    <Card text="black" className="text-center">
                      <Card.Img variant="top" src="assets/gown1.png" />
                      <Card.Body>
                        <Card.Text>Ball Gown</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="categoryCard">
                  <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <Link to="./designs" style={{ color: "white" }}>
                      View More
                    </Link>
                  </div>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <Col>
            <h1>Our Work & Customer Reviews</h1>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col md={4}>
            <Card>
              <Card.Body>
                <Card.Text>
                  <ImageCarousel height={300} images={review1} />
                  <h5 style={{ marginTop: "10px" }}>Priya Patel</h5>
                  <span>
                    They turned my old saree into a beautiful skirt that fits me
                    perfectly. The designer did an amazing job and created a
                    unique and stylish piece. I highly recommend their upcycling
                    services and fashion solutions.
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Body>
                <Card.Text>
                  <ImageCarousel height={300} images={review2} />
                  <h5 style={{ marginTop: "10px" }}>Anjali Sharma</h5>
                  <span>
                    My custom-made blouse from Esilai fits me well. The tailors
                    provided excellent service and were highly knowledgeable. I
                    would suggest them to anyone searching for clothing with
                    personalised stitching.
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Body>
                <Card.Text>
                  <ImageCarousel height={300} images={review3} />
                  <h5 style={{ marginTop: "10px" }}>Ayesha Khan</h5>
                  <span>
                    I got a shurg and a skirt made at VMake and it fits
                    perfectly. The tailors were really skilled and listened to
                    my preferences. Overall, I'm really happy with their service
                    and would definitely recommend them.
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <div style={{ textAlign: "end", marginTop: "10px" }}>
            <Link to="./we-make" style={{ color: "black" }}>
              View More
            </Link>
          </div>
        </Row>
        <Card
          style={{
            backgroundColor: "#704342",
            marginTop: "40px",
            color: "white",
          }}
        >
          <Row style={{ margin: "20px" }}>
            <h1>How to Proceed</h1>
          </Row>
          <Row style={{ padding: "15px", marginBottom: "20px" }}>
            <Col xs={12} md={4}>
              <div style={{ textAlign: "center" }}>
                <img
                  src="assets/select.png"
                  style={{ height: "100px", marginBottom: "20px" }}
                />
                <h5>1. Select Design</h5>
                <span>
                  Pick the design you want from the wide variety of options we
                  provide
                </span>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div style={{ textAlign: "center" }}>
                <img
                  src="assets/connect.png"
                  style={{ height: "100px", marginBottom: "20px" }}
                />
                <h5>2. Let Us Connect</h5>
                <span>
                  We will connect with you to confirm the size and other details
                  about your order
                </span>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div style={{ textAlign: "center" }}>
                <img
                  src="assets/order.png"
                  style={{ height: "100px", marginBottom: "20px" }}
                />
                <h5>3. Place Order</h5>
                <span>
                  Place your order and we will make sure it reaches your
                  doorstep
                </span>
              </div>
            </Col>
          </Row>
        </Card>
        <Row style={{ marginTop: "40px", marginBottom: "10px" }}>
          <h1>Contact Us</h1>
        </Row>
        <Row>
          <Col xs={12} md={6} style={{ textAlign: "center" }}>
            You can write your queries here and we will connect with you soon
            <br></br>
            <img src="assets/contact.png" style={{ maxWidth: "70%" }} />
          </Col>
          <Col xs={12} md={6}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home;
