import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ContactForm from "../components/contact_form";

function ContactUs() {
  return (
    <Container style={{ marginTop: "30px", marginBottom: "50px" }}>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "30px",
        }}
      >
        <Col xs={12} md={6}>
          <h2>We are always ready to Help</h2>
          <p>
            We are always ready to help with any questions or concerns you may
            have. Please don't hesitate to reach out to us using the contact
            form below or by phone or email. Our friendly team is dedicated to
            providing excellent customer service and will respond to your
            inquiry as soon as possible. We look forward to hearing from you!
          </p>
        </Col>
        <Col xs={12} md={6}>
          <img src="assets/contact.png" style={{ maxWidth: "60%" }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ padding: "20px", marginTop: "30px" }}>
            <Card.Body>
              <Container>
                <Row style={{ marginBottom: "10px" }}>
                  <h2>Write us your query</h2>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={12}
                    md={6}
                  >
                    <img
                      src="assets/contact2.png"
                      style={{ maxWidth: "100%" }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ContactForm />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px", textAlign: "center" }}>
        <Col xs={5}>
          <hr></hr>
        </Col>
        <Col xs={2}>
          <b>OR</b>
        </Col>
        <Col xs={5}>
          <hr></hr>
        </Col>
      </Row>
      <Link to="https://wa.me/+919473751945" style={{ textDecoration: "none" }}>
        <Card>
          <Container>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <Col xs={2} style={{ textAlign: "center" }}>
                <img src="assets/connect.png" style={{ height: "100px" }} />
              </Col>
              <Col xs={10}>
                <h2>Connect with us over Whatsapp</h2>
              </Col>
            </Row>
          </Container>
        </Card>
      </Link>
    </Container>
  );
}

export default ContactUs;
