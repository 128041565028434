import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Cookies from "js-cookie";
import { useState } from "react";
import { Container, Card, Button } from "react-bootstrap";
import Loader from "../components/loading";

function Login() {
  let auth = getAuth();
  let [loading, setLoading] = useState(false);

  function attemptLogin() {
    let id = document.getElementById("id").value;
    let pass = document.getElementById("pass").value;
    if (id == null || id == "" || pass == null || pass == "") return;
    setLoading(true);
    signInWithEmailAndPassword(auth, id, pass)
      .then((userCredential) => {
        const userId = userCredential.user.uid;
        Cookies.set("uid", userId, { domian: "localhost:3000/" });
        window.location.replace("http://localhost:3000/admin");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        Cookies.remove("uid");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#3a9bdc",
        height: "100vh",
      }}
      fluid
    >
      <Card className="text-center">
        <Card.Header as="h4">Login</Card.Header>
        <Card.Body>
          <Card.Title as="h5">Enter your Id and Password</Card.Title>
          <div style={{ marginTop: "20px" }}>
            <input className="form-control" id="id" placeholder="Id"></input>
          </div>
          <div style={{ marginTop: "15px", marginBottom: "10px" }}>
            <input
              className="form-control"
              id="pass"
              placeholder="Password"
            ></input>
          </div>
          <Button variant="primary" onClick={loading ? null : attemptLogin}>
            Submit
          </Button>
        </Card.Body>
      </Card>
      <Loader loading={loading} />
    </Container>
  );
}

export default Login;
