import { Container, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { getDatabase, ref, get, remove } from "firebase/database";
import { wemake } from "../../models/wemakeModel";
import ImageCarousel from "../../components/image_carousel";
import Loader from "../../components/loading";
import MsgBox from "../../components/msgbox";

function DeleteDesign() {
  var [weMakeItem, setweMakeItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const db = getDatabase();
  const [weMakeId, setweMakeId] = useState("");
  var [result, setRes] = useState({ success: false, msg: null });

  const handleShow = (event) => {
    event.preventDefault();
    setLoading(true);
    setweMakeItem(null);
    setRes({ msg: null });
    var id = document.getElementById("id").value;
    setweMakeId(id);
    get(ref(db, `wemake/${id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          var d = new wemake(snapshot.val());
          setweMakeItem(d);
          console.log(d);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (event) => {
    event.preventDefault();
    setLoading(true);
    setRes({ msg: null });
    console.log("id " + weMakeId);
    remove(ref(db, `wemake/${weMakeId}`))
      .then(() => {
        console.log("Deleted");
        setRes({ success: true, msg: "Wemake deleted successfully" });
      })
      .catch((e) => {
        console.log(e);
        setRes({ success: false, msg: `${e}` });
      })
      .finally(() => {
        setLoading(false);
        setweMakeId("");
      });
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      <Row>
        <Col md={4} />
        <Col md={4}>
          <form className="admin-form">
            <label>
              Enter the WeMake id
              <input id="id" className="form-control" placeholder="Wemake id" />
            </label>
            <button className="button" onClick={handleShow}>
              Search
            </button>
          </form>
        </Col>
      </Row>
      <Row>
        {weMakeItem && (
          <Container>
            <Row className="d-md-none">
              <h2>{weMakeItem.name}</h2>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col xs={12} md={6}>
                <ImageCarousel
                  images={[weMakeItem.prev_img, ...weMakeItem.images]}
                  height={"450px"}
                  zoom={true}
                />
              </Col>
              <Col xs={12} md={6}>
                <div className="d-none d-md-block">
                  <h2>{weMakeItem.name}</h2>
                </div>
                <p style={{ marginTop: "10px" }}>{weMakeItem.description}</p>
                <Button
                  variant="outline-dark"
                  onClick={handleDelete}
                  style={{ marginBottom: "10px" }}
                >
                  Delete Item
                </Button>
                <MsgBox result={result} setRes={setRes} />
              </Col>
            </Row>
            <Row></Row>
          </Container>
        )}
      </Row>
      <Loader loading={loading} />
    </Container>
  );
}
export default DeleteDesign;
