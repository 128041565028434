import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function ImageCarousel({ height, images, zoom = false, handler }) {
  const [activeIndex, setActiveIdx] = useState();
  const center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  if (zoom) {
    return (
      <div>
        <Carousel controls={false} activeIndex={activeIndex}>
          {images.map((img, idx) => (
            <Carousel.Item key={img}>
              <div style={center}>
                <img
                  src={img}
                  onClick={() => {
                    if (handler != null) handler(img);
                    setActiveIdx(idx);
                  }}
                  height={height}
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {images.map((img, idx) => (
            <img
              key={img}
              src={img}
              style={{ maxWidth: "10%", margin: "5px" }}
              onClick={() => setActiveIdx(idx)}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <Carousel
        prevIcon={<img src="./assets/left_arrow.png" height="35px" />}
        nextIcon={<img src="./assets/right_arrow.png" height="35px" />}
      >
        {images.map((img) => (
          <Carousel.Item key={img}>
            <div style={center}>
              <img src={img} style={{ maxHeight: height }} />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

export default ImageCarousel;
