import "./style.css";
import FileInput from "./file-input";
import { getDatabase, ref, child, push, update } from "firebase/database";
import { useRef, useState } from "react";
import { Container, Col, Row, Modal, Carousel } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Loader from "../../components/loading";
import MsgBox from "../../components/msgbox";

function AddDesign() {
  const [domain, setDomain] = useState("women");
  const [category, setCategory] = useState("blouse");
  const [images, setImages] = useState([]);
  const canvasRef = useRef(null);
  const modalRef = useRef(null);
  const [srcImg, setSrcImg] = useState(null);
  const [crop, setCrop] = useState({ width: 50, height: 50, aspect: 2 / 3 });
  const db = getDatabase();
  const [loading, setLoading] = useState(false);
  var [result, setRes] = useState({ success: false, msg: null });

  const uploadDesign = (event) => {
    event.preventDefault();
    setLoading(true);
    setRes({ msg: null });
    const designId = push(ref(db, "designs")).key;
    const postData = {
      id: designId,
      category: category,
      domain: domain,
      images: images,
      name: document.getElementById("name").value,
      description: document.getElementById("desc").value,
      price_start: document.getElementById("low").value,
      price_end: document.getElementById("high").value,
      rating: document.getElementById("rating").value,
      visible: true,
    };
    console.log(postData);

    update(ref(db, "designs/" + designId), postData)
      .then((snapshot) => {
        setRes({ success: true, msg: "Design added successfully" });
      })
      .catch((e) => {
        console.log(e);
        setRes({ success: false, msg: `${e}` });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCroppedImg = () => {
    const image = modalRef.current;
    const canvas = canvasRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    return base64Image;
  };

  function addImageToCanvas() {
    var image = getCroppedImg();
    console.log(image);
    var list = [...images, image];
    setImages(list);
    setSrcImg(null);
  }

  return (
    <Container fluid>
      <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Col md={7}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <form className="admin-form">
              <div className="form-group">
                <label htmlFor="name">Name of Product</label>
                <input
                  id="name"
                  className="form-control"
                  placeholder="Enter product name "
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Product Description</label>
                <textarea
                  id="desc"
                  className="form-control"
                  placeholder="Description about product"
                />
              </div>
              <div className="form-group">
                <label htmlFor="domain">
                  Select Domain
                  <select
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    style={{ marginLeft: "20px" }}
                  >
                    <option value="women">Women</option>
                    <option value="children">Children</option>
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="category">
                  Select Category
                  <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    style={{ marginLeft: "20px" }}
                  >
                    <option value="blouse">Blouse</option>
                    <option value="gown">Gown</option>
                    <option value="kurti">Kurti</option>
                    <option value="lehanga">Lehanga</option>
                    <option value="plazo">Plazo</option>
                    <option value="skirt">Skirt</option>
                    <option value="suit">Suit</option>
                    <option value="top">Top</option>
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="rating">Rating</label>
                <input
                  id="rating"
                  className="form-control"
                  placeholder="Enter product rating (eg 4.5)"
                />
              </div>
              <div className="form-group">
                <label htmlFor="price">Add Price range</label>
                <Row>
                  <Col>
                    <input
                      id="low"
                      className="form-control"
                      placeholder="Start Amount"
                    />
                  </Col>
                  <Col>
                    <input
                      id="high"
                      className="form-control"
                      placeholder="End Amount"
                    />
                  </Col>
                </Row>
              </div>
              <FileInput onImageSelected={setSrcImg} />
              <div>
                <canvas
                  ref={canvasRef}
                  style={{ height: "0", width: "0", display: "none" }}
                />
              </div>
            </form>
          </div>
        </Col>
        <Col md={5}>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            {images.length > 0 ? (
              <div>
                <Carousel
                  prevIcon={<img src="../../assets/left_arrow.png" />}
                  nextIcon={<img src="../../assets/right_arrow.png" />}
                >
                  {images.map((img) => (
                    <Carousel.Item key={img}>
                      <img src={img} height="364px" />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            ) : (
              <img src="../assets/noimg.png" />
            )}
          </div>
          <MsgBox result={result} setRes={setRes} />
          <Modal show={srcImg != null} onHide={() => setSrcImg(null)}>
            <Modal.Header closeButton>
              <Modal.Title>Crop Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col style={{ textAlign: "center" }}>
                    <ReactCrop crop={crop} onChange={setCrop} aspect={2 / 3}>
                      <img src={srcImg} ref={modalRef} />
                    </ReactCrop>
                    <div>
                      <button onClick={() => addImageToCanvas()}>Crop</button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      <Row>
        <button className="button" onClick={uploadDesign}>
          Save & Upload
        </button>
      </Row>
      <Loader loading={loading} />
    </Container>
  );
}
export default AddDesign;
