import { Container, Nav, Row } from "react-bootstrap";
import { useState } from "react";
import AddDesign from "./add-design";
import EditDesign from "./edit-design";
import DeleteDesign from "./delete-design";

function Design() {
  const [currentPage, setPage] = useState(1);

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return <AddDesign />;
      case 2:
        return <EditDesign />;
      case 3:
        return <DeleteDesign />;
      default:
        return <span></span>;
    }
  };

  return (
    <div>
      <h2 style={{ textAlign: "center", marginTop: "20px" }}>Designs</h2>
      <Container>
        <Row>
          <Nav variant="pills" defaultActiveKey="1">
            <Nav.Item>
              <Nav.Link eventKey={1} onClick={() => setPage(1)}>
                Add Design
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={2} onClick={() => setPage(2)}>
                Edit Design
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={3} onClick={() => setPage(3)}>
                Delete Design
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
        <Row>{renderPage()}</Row>
      </Container>
    </div>
  );
}

export default Design;
