function Rating({ val, count, flag }) {
  return (
    <span
      style={{
        border: "2px solid green",
        borderRadius: "10px",
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "6px",
        paddingRight: "6px",
      }}
    >
      {val}
      <span style={{ color: "green" }}> ★ </span>
      {flag ? (
        <span style={{ fontSize: "14px" }}>| {count} Ratings </span>
      ) : (
        <span></span>
      )}
    </span>
  );
}

export default Rating;
