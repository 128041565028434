import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <div>
      <h2 style={{ textAlign: "center", marginTop: "20px" }}>Dashboard</h2>
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <Link to="/admin/designs" style={{ textDecoration: "none" }}>
              <div className="roundedCard">
                <img src="../assets/icons/design.png" />
                <h4 style={{ marginTop: "15px", color: "black" }}>Designs</h4>
              </div>
            </Link>
          </Col>

          <Col xs={12} md={4}>
            <Link to="/admin/orders" style={{ textDecoration: "none" }}>
              <div className="roundedCard">
                <img src="../assets/icons/order.png" />
                <h4 style={{ marginTop: "15px", color: "black" }}>Orders</h4>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={4}>
            <Link to="/admin/reviews" style={{ textDecoration: "none" }}>
              <div className="roundedCard">
                <img src="../assets/icons/review.png" />
                <h4 style={{ marginTop: "15px", color: "black" }}>Reviews</h4>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={4}>
            <Link to="/admin/we-make" style={{ textDecoration: "none" }}>
              <div className="roundedCard">
                <img src="../assets/icons/wemake.png" />
                <h4 style={{ marginTop: "15px", color: "black" }}>We Make</h4>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={4}>
            <Link to="/admin/homepage" style={{ textDecoration: "none" }}>
              <div className="roundedCard">
                <img src="../assets/icons/homepage.png" />
                <h4 style={{ marginTop: "15px", color: "black" }}>Homepage</h4>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Dashboard;
