import { Row, Col } from "react-bootstrap";
import Rating from "../components/rating";
function Review({ value, review, name }) {
  return (
    <>
      <Col md={2} xs={3} style={{ marginTop: "20px" }}>
        <Rating val={value} flag={false} />
      </Col>
      <Col style={{ marginTop: "15px" }}>
        <Row>
          <Col>{review}</Col>
        </Row>
        <Row style={{ fontSize: "12px", color: "grey" }}>
          <Col>{name}</Col>
        </Row>
      </Col>
    </>
  );
}
export default Review;
