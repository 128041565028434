export class wemake {
  constructor(data) {
    this.id = data["id"];
    this.description = data["description"];
    this.prev_img = data["prev_img"];
    this.images = data["images"];
    this.designId = data["designId"];
    this.name = data["name"];
  }
}
