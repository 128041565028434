import { Outlet } from "react-router-dom";
import MyFooter from "../components/footer";
import MyNav from "../components/nav";

function Layout() {
  return (
    <div style={{ backgroundColor: "#EFEFEF" }}>
      <MyNav />
      <Outlet />
      <MyFooter />
    </div>
  );
}

export default Layout;
