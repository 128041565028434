import { Container, Nav, Row } from "react-bootstrap";
import { useState } from "react";
import AddWeMake from "./add-wemake";
import EditWeMake from "./edit-wemake";
import DeleteWeMake from "./delete-wemake";

function WeMakeAdmin() {
  const [currentPage, setPage] = useState(1);

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return <AddWeMake />;
      case 2:
        return <EditWeMake />;
      case 3:
        return <DeleteWeMake />;
      default:
        return <span></span>;
    }
  };

  return (
    <div>
      <h2 style={{ textAlign: "center", marginTop: "20px" }}>We Make</h2>
      <Container>
        <Row>
          <Nav variant="pills" defaultActiveKey="1">
            <Nav.Item>
              <Nav.Link eventKey={1} onClick={() => setPage(1)}>
                Add We-Make
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={2} onClick={() => setPage(2)}>
                Edit We-Make
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={3} onClick={() => setPage(3)}>
                Delete We-Make
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
        <Row>{renderPage()}</Row>
      </Container>
    </div>
  );
}

export default WeMakeAdmin;
