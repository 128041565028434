export class review {
  constructor(data) {
    this.id = data["id"];
    this.description = data["description"];
    this.images = data["images"];
    this.designId = data["designId"];
    this.rating = data["rating"];
    this.name = data["name"];
  }
}
//"Bhani", "03-01-2023", "I m loving it", 4, 17);
