export default function NotFound() {
  return (
    <div style={{ margin: "50px" }}>
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "15vw" }}>404</div>
        <div style={{ fontSize: "5vw" }}>Page Not Found</div>
      </div>
    </div>
  );
}
